// import { Environment } from './environment.type';

// /**
//  * @file Defines the environment configuration for the production environment.
//  */

// /**
//  * Environment configuration object for production.
//  * @type {Environment}
//  */
// export const environment: Environment = {
//   /** Indicates if the environment is in production mode. */
//   production: false,
//   /** API URL for accessing backend services. */
//   apiUrl: 'https://api.enaya.med.sa/api/v1',
//   /** API URL for handling payments. */
//   paymentApiUrl: 'https://api.enaya.med.sa/api/v1',
//   /** URL for accessing the heperpay service. */
//   heperpayURL: 'https://eservices.enaya.med.sa/',
//   /** URL for uploading files. */
//   uploadUrl: 'https://eservices.enaya.med.sa',
//   /** Key for accessing specific services. */
//   key: '2gNBG5t8rBqpkzSGFdu4C2:3b13LeJxgxnx0ELdyF0L5K',
//   /** Secret key for reCAPTCHA verification. */
//   recaptchaSecret: '6LfDqn0UAAAAAOF7K8_U54lmRfL7M0DjUZxvRM0Z',
//   /** API URL for verifying reCAPTCHA responses. */
//   recaptchaVerifyApi: 'https://www.google.com/recaptcha/api/siteverify',
//   /** Site key for reCAPTCHA integration. */
//   recaptchaSite: '6LfDqn0UAAAAAG_TdD-kOu5t1iUHt902vsi32LdB',
//   /** Configuration for Agora service. */
//   agora: {
//     /** Application ID for Agora service. */
//     appId: '3c2103c26c834007a1a34702d5917e69',
//   },
//   /** Configuration for Firebase service. */
//   firebase: {
//     /** API key for Firebase service. */
//     apiKey: 'AIzaSyB4BBdi1vJYnCbD5VaAPII06vskHCseJ10',
//     /** Auth domain for Firebase authentication. */
//     authDomain: 'giftstore-c2cb2.firebaseapp.com',
//     /** Project ID for Firebase project. */
//     projectId: 'giftstore-c2cb2',
//     /** Storage bucket for Firebase storage. */
//     storageBucket: 'giftstore-c2cb2.appspot.com',
//     /** Messaging sender ID for Firebase messaging. */
//     messagingSenderId: '980030829818',
//     /** Application ID for Firebase app. */
//     appId: '1:980030829818:web:344f19346084e5f60e8543',
//     /** Measurement ID for Firebase analytics. */
//     measurementId: 'G-4RVGX4K5QT',
//   },
//   /** Configuration for Google Maps service. */
//   googleMaps: {
//     /** API key for Google Maps service. */
//     apiKey: 'AIzaSyCzRBKR_nzG8pFoRuGlbS-cnxUPyc-3p0k',
//     /** Secret key for Google Maps service. */
//     secret: '1RqyEsFeROL0NIyyyQMeJgbXBts=',
//   },
// };

export const environment = {
  production: false,
  // apiUrl: 'http://localhost:3000/api/v1',
  // paymentApiUrl: 'http://localhost:3000/api/v1',
  apiUrl: 'https://enaya.bexpert.sa/api/v1',
  paymentApiUrl: 'https://enaya.bexpert.sa/api/v1',
  heperpayURL: 'https://enaya.bexpert.sa/',
  uploadUrl: 'https://enaya.bexpert.sa/',
  // apiUrl: 'https://api.enaya.med.sa/api/v1',
  // paymentApiUrl: 'https://api.enaya.med.sa/api/v1',
  // heperpayURL: 'https://eservices.enaya.med.sa/',
  // uploadUrl: 'https://eservices.enaya.med.sa',
  key: '2gNBG5t8rBqpkzSGFdu4C2:3b13LeJxgxnx0ELdyF0L5K',
  recaptchaSecret: '6LfDqn0UAAAAAOF7K8_U54lmRfL7M0DjUZxvRM0Z',
  recaptchaVerifyApi: 'https://www.google.com/recaptcha/api/siteverify',
  recaptchaSite: '6LfDqn0UAAAAAG_TdD-kOu5t1iUHt902vsi32LdB',
  agora: {
    appId: '3c2103c26c834007a1a34702d5917e69',
  },
  firebase: {
    apiKey: 'AIzaSyB4BBdi1vJYnCbD5VaAPII06vskHCseJ10',
    authDomain: 'giftstore-c2cb2.firebaseapp.com',
    projectId: 'giftstore-c2cb2',
    storageBucket: 'giftstore-c2cb2.appspot.com',
    messagingSenderId: '980030829818',
    appId: '1:980030829818:web:344f19346084e5f60e8543',
    measurementId: "G-4RVGX4K5QT"
  },
  googleMaps: {
    /** API key for Google Maps service. */
    apiKey: 'AIzaSyCzRBKR_nzG8pFoRuGlbS-cnxUPyc-3p0k',
    /** Secret key for Google Maps service. */
    secret: '1RqyEsFeROL0NIyyyQMeJgbXBts=',
  }
};

